@use 'lib/styles/defs/breakpoints';

.modRelatedContent.modRelatedContentDefault {
	color: var(--sdx-color-sc-navy);

	& .teaser-card {
		white-space: normal;
		position: relative;
		margin: 0;
		margin-bottom: 24px;
		text-decoration: none;
		outline: 0;
		color: inherit;

		&__wrapper {
			background: white;
			border-top: none;
			height: 100%;
			border-radius: 12px;

			&:before {
				content: '';
				width: 1px;
				margin-left: -1px;
				float: left;
				height: 0;
				padding-top: 110%;
			}

			&:after {
				content: '';
				display: table;
				clear: both;
			}
		}

		&__content-wrapper {
			display: flex;
			flex-flow: column nowrap;
			height: 100%;
			position: relative;
			overflow: hidden;
			border: 1px solid transparent;

			&:before {
				content: '';
				border-top: 4px solid var(--sdx-color-int-blue--active);
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				opacity: 0;
				transition: opacity 100ms ease-out;
				border-radius: 12px;
			}

			&:hover {
				box-shadow: rgba(51, 51, 51, 0.16) 0px 4px 20px 0px;
				border-radius: 12px;
				border-color: var(--sdx-color-gray-tint-4);

				.teaser-card {
					&__featured-image {
						transition-duration: 800ms;
						transform: scale(1.08);
					}
				}
			}
		}

		&__image-wrapper {
			overflow: hidden;
			flex-shrink: 0;
		}

		&__featured-image {
			background-size: cover;
			background-position: top center;
			padding-top: 56%;
			aspect-ratio: 4/3;
			object-fit: cover;
			border-radius: 12px;
			flex-shrink: 0;
		}

		&__header {
			flex: 0 0 17%;

			& .header__category {
				font-size: 14px;
				line-height: 1.71;
				letter-spacing: -0.3px;
			}

			& .header__title {
				font-weight: bold;
			}

			& > .header__title:first-child {
				margin-top: 1em;
			}
		}

		&__body {
			flex: 1 1 auto;
			color: var(--sdx-color-gray-tint-2);

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				-ms-flex: 1 1 250px;
			}
		}

		&__footer {
			flex: 0 1 20%;
			margin-top: auto;

			display: flex;
			justify-content: space-between;
			align-items: flex-end;
		}

		&__more-link {
			position: relative;
			text-decoration: none;
			color: var(--sdx-color-int-blue);

			&:hover {
				text-decoration: underline;
			}

			&:before {
				position: absolute;
			}
		}

		&__more-link {
			color: var(--sdx-color-int-blue);
		}
	}

	& .card-list__document {
		color: inherit;
		background-color: inherit;
		border-radius: 12px;
	}

	.card-list__more-link {
		color: var(--sdx-color-int-blue);
		border-bottom: 1px solid var(--sdx-color-int-blue);
	}

	.card-list__title {
		font-size: 24px;
		margin-left: 12px;
	}

	.card__body {
		margin: 0 12px;
	}

	.card__item.card__item {
		background: var(--sdx-color-sc-white);
		border: 1px solid var(--sdx-color-gray-tint-7);
		border-radius: 12px;
		margin-bottom: 24px;

		.card__item-description {
			color: var(--sdx-color-gray-tint-2);
		}

		.card__item-eyebrow,
		h5 {
			color: var(--sdx-color-sc-navy);
		}

		.row {
			align-items: flex-end;
		}
	}

	.card__item-icon {
		sdx-icon {
			margin-top: -12px;
		}
	}

	.card__item:last-child {
		margin-bottom: 16px;
	}

	.card__item:hover {
		border-color: var(--sdx-color-gray-tint-4);
		box-shadow: rgba(51, 51, 51, 0.16) 0px 4px 16px 0px;
		background-color: var(--sdx-color-sc-white);
	}

	.card-list__footer-wrapper {
		width: 100%;

		.card__footer {
			text-align: center;
			padding: 24px;
		}
	}

	.card--no-color::before {
		background: none;
	}

	& .teaser-card:hover,
	& .teaser-card:active,
	& .teaser-card:focus,
	& .teaser-card:focus-within {
		border-bottom: none;
		color: inherit;

		.teaser-card__content-wrapper:before {
			opacity: 0;
		}
	}
}

.modRelatedContent.modRelatedContentArticleOverview {
	a {
		text-decoration: none;
	}

	.author-template {
		.card-content {
			display: flex;
			margin-bottom: 74px;
			flex-wrap: wrap;
			@media only screen and (min-width: breakpoints.$breakpoint-lg) {
				flex-wrap: nowrap;
			}
			@media only screen and (min-width: breakpoints.$breakpoint-sm) and (max-width: breakpoints.$breakpoint-max-sm) {
				flex-direction: row;
				flex-wrap: nowrap;
			}

			.image-holder {
				flex: 0 0 100%;
				@media only screen and (min-width: breakpoints.$breakpoint-lg) {
					flex: 0 0 205px;
				}
				@media only screen and (min-width: breakpoints.$breakpoint-sm) and (max-width: breakpoints.$breakpoint-max-sm) {
					flex: 0;
				}

				img {
					width: 100%;
					max-width: max-content;
					@media only screen and (min-width: breakpoints.$breakpoint-sm) {
						width: auto;
						max-width: 174px;
					}
					@media only screen and (min-width: breakpoints.$breakpoint-md) {
						width: 100%;
					}
					@media only screen and (min-width: breakpoints.$breakpoint-lg) {
						max-width: 204px;
					}
				}
			}

			.content-holder {
				flex: 0 0 100%;

				padding-left: 0;
				margin-top: 16px;
				@media only screen and (min-width: breakpoints.$breakpoint-sm) {
					padding-left: 28px;
				}
				@media only screen and (min-width: breakpoints.$breakpoint-md) {
					padding-left: 0;
				}
				@media only screen and (min-width: breakpoints.$breakpoint-lg) {
					padding-left: 28px;
					flex: 0 1 auto;
				}
			}
		}
	}

	.news-template {
		.card-content {
			display: flex;
			flex-wrap: nowrap;
			margin-bottom: 42px;
			padding-bottom: 42px;
			border-bottom: 1px solid #d6d8db;

			.image-holder {
				flex: 0 0 100px;
				height: 100px;
				background-size: cover;
				background-position: center;
				margin-right: 24px;

				@media only screen and (min-width: breakpoints.$breakpoint-md) {
					flex: 0 0 205px;
					height: 110px;
				}
			}

			.content-holder {
				flex: 0 1 auto;
			}
		}

		&:last-child .card-content {
			border: none;
		}
	}
}
